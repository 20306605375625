.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





.neon {
	animation: neon 1s ease infinite;
	-moz-animation: neon 1s ease infinite;
	-webkit-animation: neon 1s ease infinite;
  }
  
  @keyframes neon {
	0%,
	100% {
	  text-shadow: 0 0 1vw #FA1C16, 0 0 3vw #FA1C16, 0 0 10vw #FA1C16, 0 0 10vw #FA1C16, 0 0 .4vw #FED128, .5vw .5vw .1vw #806914;
	  color: #FED128;
	}
	50% {
	  text-shadow: 0 0 .5vw #800E0B, 0 0 1.5vw #800E0B, 0 0 5vw #800E0B, 0 0 5vw #800E0B, 0 0 .2vw #800E0B, .5vw .5vw .1vw #40340A;
	  color: #806914;
	}
  }
  




  



/* 공지팝업 */
.pop01_popup1 {position:absolute; z-index:1000000000;}
.pop01_popup2 {position:absolute; z-index:1000000000;}
.pop01_popup_wrap {float:left;z-index:1000000000;}
.pop01_popup_btn_wrap {float:right;z-index:1000000000;}
.pop01_popup_btn_wrap ul li {float:left; margin:0 0 0 5px;}
.pop01_popup_btn {float:right; background:#e89006; min-width:60px; height:36px; line-height:40px; padding:0 15px 0 15px; text-align:center; display:inline-block; font-family:nanumgothic, sans-serif; color:#ffffff; font-size:12px; font-weight:600;}
.pop01_popup_box {float:left; background:#000000; border:5px solid #e89006;clear:both;z-index:1000000000;}
.pop01_popup_text {float:left; width:100%;z-index:1000000000;}
.pop01_popup_text h2 {font-size: 16px;}
.pop01_popup_font1 {float:left; width:100%; font-family:'nanumsquare', sans-serif; font-size:22px; letter-spacing:-1px; font-weight:700; color:#e89006; line-height:40px;}
.pop01_popup_font2 {float:left; width:100%; font-family:'nanumgothic', sans-serif; font-size:16px; letter-spacing:-1px; font-weight:400; color:#ffffff; line-height:28px;}





.custom-ui {
  text-align: center;
  width: 600px;
  padding: 20px;
  background: #000000;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}


.custom-ui .container {
  text-align: left;

}


.custom-ui > h3 {
  font-size: 160%;
}

.custom-ui > h4 {
  font-size: 150%;
}
.custom-ui > p {
  padding: 5px;
}


.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}




/* Board Section */

.board-section{
  padding:60px 0;
  background-color:#000000;
}
.board-section .board-pane{
  width: 390px;
  height: auto;
  min-height: 180px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  border-radius: 10px;
  border: solid 1px #ffd900;
  padding:15px 35px 15px 25px;
  margin:10px 18px;
}
.board-section .board-pane:before,
.board-section .board-pane:after{
  content:'';
  width:52px;
  height:52px;
  background-color:#181b23;
  position:absolute;
  right:-26px;
  top:0;
  bottom:0;
  margin:auto 0;
  border-radius:50%;
  border:solid 2px #856123;
}

.board-section .board-pane:after{
  background-color:transparent;
  /* background-image:url(../img/icon/dollars.png); */
  background-size:31px;
  background-position:center;
  background-repeat:no-repeat;
  border:none;
  border-radius:0;
}

.board-section .board-pane.withdraw:after{
  /* background-image:url(../img/icon/planet.png); */
  background-size:28px;
}

.board-section .board-pane.service:after{
  /* background-image:url(../img/icon/headset.png); */
  background-size:30px;
}
.board-section .board-pane .header{
  width: 100%;
  height: auto;
  float: left;
  text-align: left;
  color:#cc9966;
  font-family:SCDream6;
}
.board-section .board-pane table{
  width:100%;
}
.board-section .board-pane table td{
  color:#fff;
  font-size:12px;
  text-align:left;
  height:22px;
  max-width:170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.board-section .board-pane table .date{
  color:#ffcc99;
  text-align:right;
  white-space:nowrap;
}
.board-section .board-pane table td .text-yellow{
  color:#cc9900;
}
.board-section .board-pane table td .text-red{
  color:#cc3333;
}



.rolling-realtime{
  width:100%;
}
.rolling-realtime ul{
  width:100%;
  display:table;
  float:left;
  padding:0;
}
.rolling-realtime ul li {
  display:table-row;
  height:22px;
  cursor:pointer;
  transition:0.3s;
  background-color:rgba(0,0,0,0);
}
.rolling-realtime ul li:hover{
  background-color:rgba(0,0,0,0.3);
}
.rolling-realtime ul li div {
  display:table-cell;
  text-align:center;
  vertical-align:middle;
  color:#cccccc;
  font-size:12px;
  font-family:SCDream4;
}
.rolling-realtime ul li div.user{
  width:20%;
  text-align:left;
}
.rolling-realtime ul li div.amount{
  color:#ff0000;
  width:40%;
  text-align:right;
  padding-right:5%;
}
.rolling-realtime ul li div.date{
  width:40%;
  color:#ffcc99;
  text-align:right;
}


.service-content{
  width:100%;
  display:flex;
  align-items:flex-start;
  justify-content:center;
}
.service-content .dl-link{
  display:inline-block;
  margin:0 10px;
  color:#999999;
  font-size:12px;
  white-space:nowrap;
  transition:0.3s;
}
.service-content .dl-link:hover{
  color:#ff6d6d;
  text-decoration:none;
}
.service-content .dl-link img{
  transition:0.3s;
}
.service-content .dl-link:hover img{
  transform:rotate(20deg);
}
.service-content .info p{
  color:#565f69;
  font-size:12px;
  white-space:pre-line;
  margin:0;
}
.service-content .info button{
  width:127px;
  height:24px;
  background-color:#565f69;
  border-radius:3px;
  border:none;
  margin-top:11px;
  color:#eceff3;
  font-size:11px;
  transition:0.3s;
}
.service-content .info button:hover{
  background-color:#345579;
  color:#fff;
}

.react-confirm-alert-overlay {
  z-index: 99999999;
}